<template>
  
        <div class="request-back-area section-padding30">
            <div class="container">
                <div class="row d-flex justify-content-between">
                    <div class="col-xl-4 col-lg-5 col-md-5">
                        <div class="request-content">
                            <h3 v-html="tituloForm"></h3>
                            <p v-html="textoForm"></p>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-7 col-md-7">
                        <!-- Contact form Start -->
                        <div class="form-wrapper">
                            <form>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-box  mb-30">
                                            <input v-model="nome" type="text" name="name" placeholder="Nome">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-box mb-30">
                                            <input v-model="telefone" type="text" name="whatsapp" placeholder="Telefone">
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-8 mb-30">
                                        <div class="form-box mb-30">
                                            <input v-model="atuacao" type="text" name="Atuacao" placeholder="Área de Atuação">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <button type="button" class="send-btn" @click="enviarDadosFormulario">Quero contato</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>     <!-- Contact form End -->
                </div>
            </div>
        </div>

</template>


<script>
//style="border-radius:25px;"
 //import { Carousel, Slide } from 'vue-carousel';
export default {
  components: {
           // Carousel,
           // Slide,
        },
  data() {
    return {
      Formulario:[],
      tituloForm:'',
      textoForm:'',
       contatoEmail:'',
      // Objeto Contato  
      nome: '',
			email: '',
			telefone: '',
			atuacao: '',    
      Contato: {
        NomeEmpresa: "TradeBerger ",
        Assunto: "Contato Site Home",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }
    }
  },
  created() {

     this.$http
    .get(this.$apiUrl + "/institucional/telas/formulario")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.Formulario = retornoAPI
      if (this.Formulario!=''){
        this.contatoEmail = this.Formulario[0].referencia
        this.tituloForm = this.Formulario[0].resumo1
        this.textoForm = this.Formulario[0].descricao1
      }
    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmail

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome: " + this.nome + 
        " <br /> Telefone: " + this.telefone +
        " <br /> Atuacao: " + this.atuacao

      //validar vazios
      if(this.nome == '' || this.telefone == '' || this.atuacao == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
        // console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            this.$mensagem_normal("Enviado com sucesso!")
            this.nome = ''
            this.telefone = ''
            this.email = ''
            this.mensagem = ''
            this.Contato.Assunto=""
        },error => {
          this.$mensagem_normal(error.bodyText)
          this.nome = ''
          this.telefone = ''
          this.email = ''
          this.mensagem = ''
          this.Contato.Assunto=""
        });

      }
     
    }
  }
}
</script>

<style>

</style>