<template>

  <!-- Testimonial Start -->
  <div v-if="CadDepoimentos!=''" class="fix section-padding2">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-tittle text-center">
            <h2>{{ DepoimentosFundo[0].nome }}</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-9 col-lg-9 col-md-9">
          <div class="h1-testimonial-active">
          <b-carousel
              id="carousel-1"
              :interval="5000"
              controls
              indicators
              img-width="1024"
              img-height="450"
            >

              <!-- 1 -->
              <b-carousel-slide 
               v-for="depoimentos in CadDepoimentos" :key="depoimentos.institucionalId"
                img-blank img-alt="Blank image"
              >
                <div>
                <div class="single-testimonial pt-65" style="justify-content:center !important;">
                  <!-- Testimonial tittle -->
                  <div class="testimonial-icon mb-45">
                    <img :src="$apiUrlIndex + depoimentos.institucionalImg[0].urlImagem" class="ani-btn" style="height: 115px;" alt="">
                  </div>
                  <!-- Testimonial Content -->
                  <div class="testimonial-caption text-center">
                    <p v-html="depoimentos.descricao1"></p>
                    <div class="rattiong-caption">
                      <span>{{ depoimentos.nome }}</span>
                    </div>
                  </div>
                </div>
                </div>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Testimonial End -->
</template>


<script>
//import { Carousel, Slide } from 'vue-carousel';
export default {
  components: {
    //Carousel,
   // Slide,
  },
  data() {
    return {
      DepoimentosFundo: [],
      CadDepoimentos: [],
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/depoimentostítulo")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.DepoimentosFundo = retornoAPI
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/caddepoimentos")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.CadDepoimentos = retornoAPI.filter((x) => x.visivel)

        this.CadDepoimentos.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })
      });

  }
}
</script>

<style>
.VueCarousel-wrapper {
  width: 100%;
  position: relative;
  overflow: visible !important;
}

.VueCarousel-inner {
    display: flex;
    flex-direction: row;
    backface-visibility: hidden;
    justify-content: center;
}

</style>