<template>

  <div>
    <!-- slider Area Start-->
    <div class="slider-area ">
      <!-- Mobile Menu -->
      <div class="single-slider slider-height2 d-flex align-items-center"
        :style="{ backgroundImage: 'url(' + $apiUrlIndex + imgTopo + ')'}">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap text-center">
                <h2>{{ topoTitulo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- slider Area End-->

    <!-- We Trusted Start-->
    <div class="we-trusted-area ">
      <div class="container">
        <div class="row d-flex align-items-end">
          <div class="col-xl-7 col-lg-7">
            <div class="trusted-img">
              <img :src="$apiUrlIndex + sobreImg" alt="">
            </div>
          </div>
          <div class="col-xl-5 col-lg-5">
            <div class="mt-5" style="padding-top: 60px;">
              <h2>{{ sobreTitulo }}</h2>
              <p style="text-align: justify;" v-html="sobreDescricao">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- We Trusted End-->
    <!-- services Area Start-->
    <div class="services-area section-padding2">
      <div class="container">
        <!-- section tittle -->
        <div class="row">
          <div class="col-lg-12">
            <div class="section-tittle text-center">
              <h2>{{ servicosTitulo }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-for="servico in Servicos" :key="servico.institucionalId" class="col-xl-4 col-lg-4 col-md-6">
            <div class="single-services text-center">
              <div class="services-icon">
                <span class="flaticon-checklist"></span>
              </div>
              <div class="services-caption">
                <h4 v-html="servico.resumo1"></h4>
                <p v-html="servico.descricao1"></p>
              </div>
            </div>
          </div>
        </div>
        <!-- button -->
        <div class="row justify-content-center">
          <div class="room-btn" style="text-align: center;">
            <router-link to="/contato" class="border-btn">Solicite Contato</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- services Area End-->

    <Depoimentos />

    <!-- Recent Area Start -->
    <div class="recent-area section-padding">
      <div class="container">
        <!-- section tittle -->
        <div class="row">
          <div class="col-lg-12">
            <div class="section-tittle text-center">
              <h2>{{ tituloblog }}</h2>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div v-for="blog in Blogs.slice(0, 3)" :key="blog.institucionalId" class="col-xl-4 col-lg-4 col-md-6">
            <div class="single-recent-cap mb-30">
              <div class="recent-img">
                <router-link :to="/blog/ + blog.institucionalId">
                  <img v-if="blog.institucionalImg != ''" :src="$apiUrlIndex + blog.institucionalImg[0].urlImagem"
                    alt="">
                </router-link>
              </div>
              <div class="recent-cap">
                <p>{{ currentDateTime(blog.data1) }}</p>
                <h4><router-link :to="/blog/ + blog.institucionalId">{{ blog.nome }}</router-link></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Recent Area End-->

    <!-- Request Back Start -->

    <Formulario />
  </div>
</template>

<script>
//import Header from '../shared/Header.vue'
import Depoimentos from '../shared/Depoimentos.vue'
import Formulario from '../shared/Formulario.vue'
import moment from 'moment'

export default {
  components: {
    //Header,
     Formulario,
    Depoimentos
  },
  data() {
    return {
      topoInternas: [],
      topoTitulo: '',
      imgTopo: '',

      sobre: [],
      sobreTitulo: '',
      sobreImg: '',
      sobreDescricao: '',

      ServicoTitulo: [],
      servicosTitulo: '',
      Servicos: [],
      BlogTitulo: [],
      tituloblog: '',
      Blogs: [],
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/toposobre")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.topoInternas = retornoAPI

        if (this.topoInternas != '') {
          this.topoTitulo = this.topoInternas[0].nome
          this.imgTopo = this.topoInternas[0].institucionalImg[0].urlImagem
        }

      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/descricaosobre")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.sobre = retornoAPI

        this.sobreTitulo = this.sobre[0].nome
        this.sobreDescricao = this.sobre[0].descricao1
        this.sobreImg = this.sobre[0].institucionalImg[0].urlImagem

      });



    this.$http
      .get(this.$apiUrl + "/institucional/telas/serviçostítulo")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.ServicoTitulo = retornoAPI

        if (this.ServicoTitulo != '') {
          this.servicosTitulo = this.ServicoTitulo[0].nome
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/servicoshome")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Servicos = retornoAPI.filter((x) => x.visivel)

        this.Servicos.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/títulonews")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.BlogTitulo = retornoAPI

        if (this.BlogTitulo != '') {
          this.tituloblog = this.BlogTitulo[0].nome
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/cadnoticias")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Blogs = retornoAPI.filter((x) => x.visivel)

        this.Blogs.sort(function (a, b) {
          if (a.data1 > b.data1) { return 1; }
          if (a.data1 < b.data1) { return -1; }
          return 0;
        }).reverse();
      });



  },
  methods: {
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    },
  }

}

</script>

