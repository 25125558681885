<template>

  <footer>
    <!-- Footer Start-->
    <div class="footer-area footer-padding">
      <div class="container">
        <div class="row d-flex justify-content-between">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <div class="single-footer-caption mb-50">
              <div class="single-footer-caption">
                <!-- logo -->
                <div class="footer-logo">
                  <router-link to="/"><img src="/assets/img/logo/logo_topo.png" style="height: 110px;" alt="">
                  </router-link>
                </div>
                <div class="footer-tittle">
                  <div class="footer-pera">
                    <span v-html="sobreRodape"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5">
            <div class="single-footer-caption mb-50">
              <div class="footer-tittle">
                <h4>Empresa</h4>
                <ul style="padding-left: 0px;">
                  <li>
                    <router-link to="/">Home</router-link>
                  </li>
                  <li>
                    <router-link to="/sobre">Sobre a TradeBerger</router-link>
                  </li>
                  <li>
                    <router-link to="/segmentos">Segmentos</router-link>
                  </li>
                  <li>
                    <router-link to="/blog">Blog</router-link>
                  </li>
                  <li>
                    <router-link to="/contato">Contato</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5">
            <div class="single-footer-caption mb-50">
              <div v-if="Endereco != '' && Endereco != null" class="footer-tittle">
                <h4>Contatos</h4>
                <span class="text" v-html="Endereco"></span>
                <!-- social -->
                <div class="footer-social">
                  <a v-if="facebookLink != ''" :href="facebookLink" target="_blank"><i
                      class="fab fa-facebook-square"></i></a>
                  <a v-if="instagramLink != ''" :href="instagramLink" target="_blank"><i
                      class="fab fa-instagram"></i></a>
                  <a v-if="linkedinLink != ''" :href="linkedinLink" target="_blank"><i class="fab fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer-bottom aera -->
    <div class="footer-bottom-area footer-bg">
      <div class="container">
        <div class="footer-border">
          <div class="row d-flex align-items-center">
            <div class="col-xl-12 ">
              <div class="footer-copy-right text-center">
                <a href="https://avancedigital.com.br/" target="_blank">
                  <img class="logo_avance" src="/assets/img/logo/logo-avance.png">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer End-->
  </footer>
</template>


<script>
export default {
  data() {
    return {
      infoRodape: [],
      sobreRodape: '',
      Endereco: '',
      socialFacebook: [],
      facebookLink: '',
      socialInstagram: [],
      instagramLink: '',
      socialLinkedin: [],
      linkedinLink: '',
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/info%20rodape")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.infoRodape = retornoAPI
        if (this.infoRodape != '') {
          this.sobreRodape = this.infoRodape[0].resumo1
          this.Endereco = this.infoRodape[0].resumo2
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/facebook")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.socialFacebook = retornoAPI.filter((x) => x.visivel)
        if (this.socialFacebook != '') {
          this.facebookLink = this.socialFacebook[0].linkBtn1
        }


      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/instagram")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.socialInstagram = retornoAPI.filter((x) => x.visivel)
        if (this.socialInstagram != '') {
          this.instagramLink = this.socialInstagram[0].linkBtn1
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/linkedin")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.socialLinkedin = retornoAPI.filter((x) => x.visivel)
        if (this.socialLinkedin != '') {
          this.linkedinLink = this.socialLinkedin[0].linkBtn1
        }
      });

  },
  methods: {
  }
}
</script>

<style>
a {
  text-decoration: none !important;
}
</style>