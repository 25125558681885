<template>

    <div>
        <!-- slider Area Start-->
        <div class="slider-area ">
            <!-- Mobile Menu -->
            <div class="single-slider slider-height2 d-flex align-items-center"
                :style="{ backgroundImage: 'url(' + $apiUrlIndex + imgTopo + ')' }">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="hero-cap text-center">
                                <h2>{{ topoTitulo }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- slider Area End-->

        <!-- Services Details Start -->
        <div class="services-details section-padding2">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 col-lg-8 d-flex" style="border-bottom: 1px solid #cccccc;">
                        <div class="hero-cap text-center">
                            <h4>{{ Empresa.nome }}</h4>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 f-right">
                        <div class="footer-social f-right">
                            <a v-if="Empresa.campoExtra1 != ''" :href="Empresa.campoExtra1"><i
                                    class="fab fa-facebook-square"
                                    style="color:  #333333;font-size: 30px;padding-right: 3px;"></i></a>
                            <a v-if="Empresa.campoExtra2 != ''" :href="Empresa.campoExtra2"><i class="fab fa-instagram"
                                    style="color:  #333333;font-size: 30px;padding-right: 3px;"></i></a>
                            <a v-if="Empresa.campoExtra3 != ''" :href="Empresa.campoExtra3"><i class="fab fa-linkedin"
                                    style="color:  #333333;font-size: 30px;padding-right: 3px;"></i></a>
                            <a v-if="Empresa.campoExtra6 != ''" :href="Empresa.campoExtra6"><i
                                    class="fab fa-whatsapp-square" style="color: green;font-size: 30px;"></i></a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8 col-lg-8">
                        <div class="s-details-caption" style="padding-top: 30px;">
                            <p class="details-pera1" v-html="Empresa.descricao1"></p>
                        </div>
                        <div class="s-detailsImg">
                            <p class="details-pera1" v-html="Empresa.descricao2"></p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 blog_right_sidebar">

                        <aside class="single_sidebar_widget newsletter_widget" style="padding-top: 30px;">
                            <h4 class="widget_title">Fale Conosco</h4>

                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control mb-2" onfocus="this.placeholder = ''"
                                        onblur="this.placeholder = 'Nome'" placeholder='Nome' required />
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control mb-2" onfocus="this.placeholder = ''"
                                        onblur="this.placeholder = 'E-mail'" placeholder='E-mail' required />
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control mb-2" onfocus="this.placeholder = ''"
                                        onblur="this.placeholder = 'Telefone'" placeholder='Telefone' required />
                                </div>
                                <button type="button" class="send-btn" @click="enviarDadosFormulario">Quero Contato</button>
                            </form>
                        </aside>
                        <aside class="single_sidebar_widget instagram_feeds">
                            <h4 class="">Galeria</h4>
                            <div class="row gallery-item">
                                 <div class="images" v-viewer>
                                    <img v-for="src in Empresa.institucionalImg" :src="$apiUrlIndex + src.urlImagem" :key="src.institucionalImgId" style="height: 60px;padding-right: 5px;">
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
        <!-- Services Details End -->
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
export default {
    components: {
    },
    data() {
        return {
            idEmpresa: this.$route.params.EmpresaId,
            topoInternas: [],
            topoTitulo: '',
            imgTopo: '',
            Empresa: [],
            contatoEmail: '',
            // Objeto Contato  
            nome: '',
            email: '',
            telefone: '',
            Contato: {
                NomeEmpresa: "TradeBerger Parceiros ",
                Assunto: "Contato",
                Destinatario: "",
                EmailResposta: "",
                TextoEmail: ""
            }
        }
    },
    created() {

        this.$http
            .get(this.$apiUrl + "/institucional/telas/toposegmentos")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.topoInternas = retornoAPI

                if (this.topoInternas != '') {
                    this.topoTitulo = this.topoInternas[0].nome
                    this.imgTopo = this.topoInternas[0].institucionalImg[0].urlImagem
                }
            });

        this.$http
            .get(this.$apiUrl + "/institucional/" + this.idEmpresa)
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.Empresa = retornoAPI
            });


    },
    methods: {
        enviarDadosFormulario() {

            //Email que irá receber os dados do formulário preenchido (empresa site)
            this.Contato.Destinatario = this.Empresa.referencia

            //Email de quem preencheu formulário e enviou email (usuário/cliente)
            this.Contato.EmailResposta = this.email

            // Dados que aparecerão no corpo do email
            this.Contato.TextoEmail =
                "Nome: " + this.nome +
                " <br /> Telefone: " + this.telefone +
                " <br /> Email: " + this.email 

            //validar vazios
            if (this.nome == '' || this.telefone == '' || this.email == '') {
                this.$mensagem_normal("Preencha todos os campos");

            } else {

                //SE NAO TIVER ANEXO:
                // console.log(this.Contato)

                this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
                    .then(response => {
                        if (response.ok)
                            this.$mensagem_normal("Enviado com sucesso!")
                        this.nome = ''
                        this.telefone = ''
                        this.email = ''
                        this.Contato.Assunto = ""
                    }, error => {
                        this.$mensagem_normal(error.bodyText)
                        this.nome = ''
                        this.telefone = ''
                        this.email = ''
                        this.Contato.Assunto = ""
                    });

            }

        }
    }
}

</script>

<style>
</style>