<template>

    <div>
        <div class="slider-area ">
        <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators
            img-width="1024" img-height="480" class="slider-height d-flex align-items-center">
            <!-- Slides with custom text -->
            <b-carousel-slide v-for="slider in Sliders" :key="slider.institucionalId" :img-src="$apiUrlIndex + slider.institucionalImg[0].urlImagem">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-8">
                            <div class="hero__caption" style="text-align: left;">
                                <p data-animation="fadeInLeft" data-delay=".4s">{{slider.nome}}</p>
                                <h1 data-animation="fadeInLeft" data-delay=".6s" v-html="slider.resumo1"></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </b-carousel-slide>

        </b-carousel>
        </div>

        <!-- Team-profile Start -->
        <div class="team-profile team-padding">
            <div class="container">
                <div class="row">
                    <div v-for="itemh in ItensHome" :key="itemh.institucionalId" class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Front -->
                            <div class="single-profile-front">
                                <div v-if="itemh.institucionalImg!=''" class="profile-img">
                                    <img :src="$apiUrlIndex + itemh.institucionalImg[0].urlImagem"
                                        style="height: 236px;" alt="">
                                </div>
                                <div class="profile-caption">
                                    <h4>{{ itemh.nome}}</h4>
                                    <p v-html="itemh.resumo1"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-profile mb-30">
                            <!-- Back -->
                            <div class="single-profile-back-last">
                                <h2 v-html="contatoTituloHome"></h2>
                                <p v-html="contatoTextoHome"></p>
                                <a :href="contatolinkBtn1" alt="">{{ contatotextoBtn1 }} »</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Team-profile End-->

        <!-- services Area Start-->
        <div class="services-area section-padding2">
            <div class="container">
                <!-- section tittle -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-tittle text-center">
                            <h2>{{ servicosTitulo}}</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div v-for="servico in Servicos" :key="servico.institucionalId" class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-services text-center">
                            <div class="services-icon">
                                <span class="flaticon-checklist"></span>
                            </div>
                            <div class="services-caption">
                                <h4 v-html="servico.resumo1"></h4>
                                <p v-html="servico.descricao1"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- button -->
                <div class="row justify-content-center">
                    <div class="room-btn" style="text-align: center;">
                        <router-link to="/contato" class="border-btn">Solicite Contato</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- services Area End-->

        <Depoimentos />

        <!-- Recent Area Start -->
        <div class="recent-area section-padding">
            <div class="container">
                <!-- section tittle -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-tittle text-center">
                            <h2>{{tituloblog}}</h2>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div v-for="blog in Blogs.slice(0,3)" :key="blog.institucionalId"
                        class="col-xl-4 col-lg-4 col-md-6">
                        <div class="single-recent-cap mb-30">
                            <div class="recent-img" style="height: 300px;">
                                <router-link :to="/blog/ + blog.institucionalId">
                                    <img v-if="blog.institucionalImg!=''"
                                        :src="$apiUrlIndex + blog.institucionalImg[0].urlImagem" alt="">
                                </router-link>
                            </div>
                            <div class="recent-cap">
                                <p>{{ currentDateTime(blog.data1) }}</p>
                                <h4><router-link :to="/blog/ + blog.institucionalId">{{ blog.nome }}</router-link></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Recent Area End-->

        <!-- Request Back Start -->

        <Formulario />
    </div>
</template>

<script>
//import Header from '../shared/Header.vue'
import Depoimentos from '../shared/Depoimentos.vue'
import Formulario from '../shared/Formulario.vue'
//import { Carousel, Slide } from 'vue-carousel';
//import VueJsCounter from 'vue-js-counter'
  import moment from 'moment'


export default {
  components: {
   // Header,
    Formulario,
     Depoimentos,
     // VueJsCounter 
    //Carousel,
    //Slide
   },
  data() {
    return {
      Sliders: [],
      slide: 0,
      sliding: null,
      ItensHome: [],
      SoliciteContato:[],
     contatoTituloHome:'',
     contatoTextoHome:'',
     contatolinkBtn1:'',
     contatotextoBtn1:'',
     ServicoTitulo:[],
     servicosTitulo:'',
     Servicos:[],
     BlogTitulo:[],
     tituloblog:'',
     Blogs:[],
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/slider")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.Sliders = retornoAPI

    });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/itenshome")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.ItensHome = retornoAPI.filter((x) => x.visivel)

        this.ItensHome.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })
      });
  
      this.$http
      .get(this.$apiUrl + "/institucional/telas/solicitecontato")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.SoliciteContato = retornoAPI

        if (this.SoliciteContato != '') {
          this.contatoTituloHome = this.SoliciteContato[0].resumo1
          this.contatoTextoHome = this.SoliciteContato[0].descricao1
          this.contatolinkBtn1 = this.SoliciteContato[0].linkBtn1
          this.contatotextoBtn1 = this.SoliciteContato[0].textoBtn1
        }
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/serviçostítulo")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.ServicoTitulo = retornoAPI

        if (this.ServicoTitulo != '') {
          this.servicosTitulo = this.ServicoTitulo[0].nome
        }
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/servicoshome")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Servicos = retornoAPI.filter((x) => x.visivel)

        this.Servicos.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })
      });

       this.$http
      .get(this.$apiUrl + "/institucional/telas/títulonews")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.BlogTitulo = retornoAPI

        if (this.BlogTitulo != '') {
          this.tituloblog = this.BlogTitulo[0].nome
        }
      });

       this.$http
      .get(this.$apiUrl + "/institucional/telas/cadnoticias")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Blogs = retornoAPI.filter((x) => x.visivel)

        this.Blogs.sort(function (a, b) {
          if (a.data1 > b.data1) { return 1; }
          if (a.data1 < b.data1) { return -1; }
          return 0;
        }).reverse();
      });

  },
  methods: {
      currentDateTime(vdate) {
        return moment(vdate).format('DD/MM/YYYY')
      },
       onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }
  }
  
}

</script>

<style>
.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 6.25rem !important;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

@media (max-width:767px) {
.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 0px !important;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

.slider-area .hero__caption h1 p {
    font-size: 18px;
    line-height: 1;
}
}
</style>

