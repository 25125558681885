<template>

  <div>

      <!-- slider Area Start-->
    <div class="slider-area ">
      <!-- Mobile Menu -->
      <div class="single-slider slider-height2 d-flex align-items-center"
        :style="{ backgroundImage: 'url(' + $apiUrlIndex + imgTopo + ')'}">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap text-center">
                <h2>{{ topoTitulo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- slider Area End-->

    <!--================Blog Area =================-->
    <section class="blog_area section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 mb-5 mb-lg-0">
                    <div class="blog_left_sidebar">
                        <article class="blog_item">
                          <div class="row" v-if="blog!=''">
                                  <div class="col-sm-12 pt-5" v-for="(imgs,index) in blog_img" :key="index">
                                      <img class="card-img rounded-0" :src="$apiUrlIndex + imgs.urlImagem" alt="">
                                  </div>   
                           </div>  
                            <div class="blog_details">
                               
                                <a class="d-inline-block">
                                    <h2>{{ blogTitulo }}</h2>
                                </a>
                                <br /><small>{{ currentDateTime(blogData) }}</small>
                                <br />
                                <br />
                               <p v-html="blogDescricao"></p> 
                               <a v-if="blogLink!='' && blogLink!=null" :href="blogLink" target="_blank">Saiba mais</a>   
                            </div>
                        </article>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="blog_right_sidebar">
                        <aside class="single_sidebar_widget post_category_widget">
                            <h4 class="widget_title">Últimas notícias</h4>
                            <ul class="list cat-list">
                                <li  v-for="noticia in blog" :key="noticia.institucionalId">
                                   <small>{{ currentDateTime(noticia.data1) }}</small>
                                    <a href="#" @click.prevent="carregaNoticia(noticia.institucionalId)" class="d-flex">
                                        <p>{{ noticia.nome }}</p>
                                    </a>
                                </li>
                            </ul>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--================Blog Area =================-->

  </div>
</template>

<script>
//import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'
import moment from 'moment'

export default {
  components: {
    //Header
  },
  data() {
    return {
      contato: [],
      topoTitulo:'',
      textoTitulo:'',

      blog: [],
      blogTitulo: '',
      blog_img: [],
      blogDescricao: '',
      blogData:'',
      blogLink:'',
      imgTopo:'',
      
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/topoblog")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.topoInternas = retornoAPI

        if (this.topoInternas != '') {
          this.topoTitulo = this.topoInternas[0].nome
          this.imgTopo = this.topoInternas[0].institucionalImg[0].urlImagem
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/cadnoticias")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.blog = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                        if (a.data1 > b.data1) {
                            return 1;
                        }
                        if (a.data1 < b.data1) {
                            return -1;
                        }
                        return 0;
                    }).reverse();

        this.blogTitulo = this.blog[0].nome
        this.blogLink = this.blog[0].linkBtn1;
        if (this.blog!=""){
            this.blog_img = this.blog[0].institucionalImg;
        }
       
        this.blogDescricao = this.blog[0].descricao1
        this.blogData = this.blog[0].data1

      });

    //this.carregaNoticia(this.$route.params.idNoticia) 

  },
  methods: {
     carregaNoticia(idNoticia) {   
    //  this.$http
     // .get(this.$apiUrl + "/institucional/" + idNoticia)
    //  .then((res) => res.json())
    //  .then((retornoAPI) => {
      console.log('this.blog')
      console.log(this.blog)
        this.noticia = this.blog.filter(x=> x.institucionalId == idNoticia)

        this.blogTitulo = this.noticia[0].nome
        this.blogDescricao = this.noticia[0].descricao1
        this.blogData = this.noticia[0].data1
        this.blogLink = this.noticia[0].linkBtn1;

        if (this.noticia.institucionalImg != '') {          
          this.blog_img = this.noticia[0].institucionalImg;
        }
        else {          
          this.blog_img = '';
        }
     // });
    },
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    }
  }
  
}

</script>

