<template>

  <header>
    <!-- Header Start -->
    <div class="header-area">
      <div class="main-header ">
        <div class="header-top top-bg d-none d-lg-block">
          <div class="container">
            <div class="col-xl-12">
              <div class="d-flex justify-content-between align-items-center mt-2" style="margin-right:25px;">
                <div class="header-info-left">
                  <ul style="padding-left:0px;">
                    <li><i class="fas fa-map-marker-alt"></i>{{ FoneTopo }}</li>
                    <li><i class="fas fa-envelope"></i>{{ EmailTopo }}</li>
                  </ul>
                </div>
                <div class="header-info-right">
                  <ul class="header-social">
                    <li><a v-if="linkedinLink != ''" :href="linkedinLink" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a v-if="instagramLink != ''" :href="instagramLink" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    <li><a v-if="facebookLink != ''" :href="facebookLink" target="_blank"><i class="fab fa-facebook-f"></i></a></li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-bottom header-fixed">
          <div class="container-fluid">
            <b-navbar toggleable="lg"
              class="navbar">
              <b-navbar-brand>
                <router-link to="/" class="navbar-brand">
                  <img src="/assets/img/logo/logo_topo.png" alt="Logo" class="img-thumbnail"
                    style="border: 0px;height: 105px;" />
                </router-link>
                <b-navbar-toggle target="nav-collapse"><i class="fa fa-bars"></i></b-navbar-toggle>
              </b-navbar-brand>
              <b-collapse class="main-menu" style="justify-content: flex-end;" id="nav-collapse" is-nav>
                <b-navbar-nav class="navbar-nav nav">
                  <b-nav-item exact class="nav-link" to="/"><span>Home</span></b-nav-item>
                  <b-nav-item class="nav-link" to="/sobre"><span>Sobre a TradeBerger</span></b-nav-item>
                  <b-nav-item class="nav-link" to="/segmentos"><span>Segmentos</span></b-nav-item>
                  <b-nav-item class="nav-link" to="/blog"><span>Blog</span></b-nav-item>
                  <b-nav-item class="nav-link" to="/contato"><span>Contato</span></b-nav-item>
                  <b-nav-item class="nav-link" :href="LinkTopo" style="padding-right: 0px !important;" target="_blank"><span style="background-color:#006633;padding-bottom:15px;padding-top:15px;padding-left:40px;padding-right:40px;border-radius: 10px;color:#ffffff;">{{ TextoBtnTopo }} <i class="fab fa-whatsapp"></i></span></b-nav-item>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
          </div>
        </div>
      </div>
    </div>
    <!-- Header End -->
  </header>


</template>

<script>
// import FiltraImoveis from './FiltraImoveis.vue'

export default {
  //props: ['topoFundo','topoCor'],
  components: {
    // FiltraImoveis
  },
  data() {
    return {
      socialFacebook: [],
      facebookLink: '',

      socialInstagram: [],
      instagramLink: '',

      socialLinkedin: [],
      linkedinLink: '',

      Topo:[],
      FoneTopo:'',
      EmailTopo:'',
      TextoBtnTopo:'',
      LinkTopo:'',
    }

  },
  created() {
    
     this.$http
        .get(this.$apiUrl + "/institucional/telas/topo")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.Topo = retornoAPI

          if (this.Topo != '') {
            this.FoneTopo = this.Topo[0].nome
            this.EmailTopo = this.Topo[0].campoExtra1
            this.TextoBtnTopo = this.Topo[0].textoBtn1
            this.LinkTopo = this.Topo[0].linkBtn1
          }
        }); 

    this.$http
      .get(this.$apiUrl + "/institucional/telas/facebook")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.socialFacebook = retornoAPI.filter((x) => x.visivel)
        if (this.socialFacebook != '') {
          this.facebookLink = this.socialFacebook[0].linkBtn1
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/instagram")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.socialInstagram = retornoAPI.filter((x) => x.visivel)
        if (this.socialInstagram != '') {
          this.instagramLink = this.socialInstagram[0].linkBtn1
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/linkedin")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.socialLinkedin = retornoAPI.filter((x) => x.visivel)
        if (this.socialLinkedin != '') {
          this.linkedinLink = this.socialLinkedin[0].linkBtn1
        }
      });

 }

}
</script>

<style>


</style>