<template>
  <div id="app">

    <div v-html="codigo_SEO"></div>

     <Header /> 
    <main>
      <transition name="fadeIn">
        <router-view></router-view>
      </transition>
    </main>
    <Footer />

  </div>
</template>

<script>

  import Header from './components/site/shared/Header.vue'
  import Footer from './components/site/shared/Footer.vue'

  export default {
    name: 'App',
    components: {
      Header,
      Footer
    },
    data() {
      return {

        seobody: [],
        codigo_SEO: '',
        seoHead:[],
        codigoHead: '',
        seoAnalitics:[],
        codigoAnalitics: '',
        metaHome: [],
        codigoHome: ''
        
      }
    },
    created() {
    /*  this.http
      .get(this.apiUrl + "/seobody/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seobody = retornoAPI
        this.codigo_SEO = this.seobody[0].codigo
      });


      this.http
      .get(this.apiUrl + "/seohead/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seoHead = retornoAPI;
        this.codigoHead = this.seoHead[0].codigo;
      });


      this.http
      .get(this.apiUrl + "/seoanalitycs/")
      .then((res) => res.json())
      .then((idi) => {
        this.seoAnalitics = idi;
        this.codigoAnalitics = this.seoAnalitics[0].codigo;
      });


      this.http
      .get(this.apiUrl + "/Seometahome/")
      .then((res) => res.json())
      .then((idi) => {
        this.metaHome = idi;
        this.codigoHome = this.metaHome[0].codigo;
        document.head.innerHTML += this.codigoHead.trim('"') + this.codigoAnalitics.trim('"') + this.codigoHome.trim('"');
      });*/
    },
     methods: {
  }
}
</script>

<style>
  @import '/assets/css/owl.carousel.min.css';
  @import '/assets/css/flaticon.css';
  @import '/assets/css/slicknav.css';
  @import '/assets/css/animate.min.css';
  @import '/assets/css/magnific-popup.css';
  @import '/assets/css/fontawesome-all.min.css';
  @import '/assets/css/themify-icons.css';
  @import '/aassets/css/slick.css';
  @import '/assets/css/nice-select.css';
  @import '/assets/css/style.css';


  /* ---------  */
  .ql-align-justify {
  text-align: justify !important;
  }
  .ql-align-right{
  text-align: right !important;
  }

  .ql-align-center{
  text-align: center !important;
  }


  /*---------- Animations */
  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }

@media (max-width:768px) {
    .hidden-xs {
        display: none !important;
    }

    .main-header .main-menu ul li a {
      color: #002e5b !important;
      font-weight: 500 !important;
      padding: 24px 19px !important;
      display: block !important;
      font-size: 20px !important;
      background-color: #f9f6f6;
}

}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }

  }


@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }

}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }

}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

</style>
