<template>

  <div>
    <!-- slider Area Start-->
    <div class="slider-area ">
      <!-- Mobile Menu -->
      <div class="single-slider slider-height2 d-flex align-items-center"
        :style="{ backgroundImage: 'url(' + $apiUrlIndex + imgTopo + ')' }">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap text-center">
                <h2>{{ topoTitulo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- slider Area End-->

    <!-- Completed Cases Start -->
    <div class="completed-cases section-padding2">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-6">
            <aside class="single_sidebar_widget post_category_widget">
              <h4 class="widget_title">Atuação</h4>
              <ul class="list cat-list mt-3" style="padding-left:0px;">
                <li v-for="seg in Segmentos" :key="seg.segmentoInstNivel1Id"
                  style="border-bottom: 1px solid #cccccc; padding-top: 15px;">
                  <a href="#" @click.prevent="CarregaSegmento2(seg)" class="d-flex">
                    <p>{{ seg.nomeSegmento }}</p>
                  </a>
                </li>
              </ul>
            </aside>
          </div>
          <div class="col-xl-8 col-lg-8 col-md-6">
            
            <div class="row" style="padding-bottom: 20px;padding-top: 20px;">
            <div>
               <h4 class="col-xl-12 col-lg-12 col-md-12 widget_title" style="padding-top: 12px;padding-right: 10px;padding-bottom: 10px;">{{ TituloSegmento }} >> </h4>
            </div>
              <div class="col-xl-12 col-lg-12 col-md-12 ajustacel" style="border-bottom: 1px solid #cccccc;display: inline-flex;">
               
                  <h6 v-if="mostra" class="widget_title" style="padding-top: 14px;padding-right: 10px;padding-bottom: 10px;">Filtre
                    por Categorias: </h6>
                      <v-select v-model="SegselectedNav" @input="MudaSegMobile" v-if="mostra"
                                  :options="SegMobile"
                                  :value="SegMobile.segmentoProdNivel2Id"
                                  single-line
                                  style="width: 300px;padding-top: 7px;"
                                  :searchable="false"
                                  label="nomeSegmento" class="style-chooser mt-4 mt-md-0"></v-select>
              </div>
            </div>
            <div class="row">
              <div v-for="emp in ListaEmp" :key="emp.institucionalId" class="col-xl-4 col-lg-4 col-md-6">
                <div class="single-cases-img  size mb-30">
                  <router-link :to="/segmentodetalhe/ + emp.institucionalId + '/' + emp.nome.replaceAll('/','').replaceAll(' ','-') ">
                  <img v-if="emp.institucionalImg!=''" :src="$apiUrlIndex + emp.institucionalImg[0].urlImagem" alt="">
                  </router-link>
                  <!-- img hover caption -->
                  <div class="single-cases-cap single-cases-cap2">
                    <h4><router-link :to="/segmentodetalhe/ + emp.institucionalId + '/' + emp.nome.replaceAll('/','').replaceAll(' ','-')" style="font-size: 16px;">{{ emp.nome }}</router-link></h4>
                    <p style="font-size: 12px;">{{ emp.segmentoInstNivel2.nomeSegmento }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Completed Cases end -->

    <Formulario />
  </div>
</template>

<script>
import Formulario from '../shared/Formulario.vue'
import vSelect from 'vue-select';

export default {
  components: {
    Formulario,
    vSelect
  },
  data() {
    return {
      SegmentoNivel1Id: this.$route.params.Seg1id,
      SegmentoNivel2Id: this.$route.params.Seg2id,
      topoInternas: [],
      topoTitulo: '',
      imgTopo: '',
      SegselectedNav: '',
      SegMobile: [],
      Segmentos:[],
      DadosSegmentoNivel2:[],
      Empresas:[],
      ListaEmp:[],
      ListaEmpDestaque:[],
      TituloSegmento:'',
      mostra:false,
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/toposegmentos")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.topoInternas = retornoAPI

        if (this.topoInternas != '') {
          this.topoTitulo = this.topoInternas[0].nome
          this.imgTopo = this.topoInternas[0].institucionalImg[0].urlImagem
        }
      });

    this.$http
      .get(this.$apiUrl + "/segmentoInstNivel1")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Segmentos = retornoAPI.filter((x) => x.visivel)
        this.Segmentos.sort(function (a, b) {
          if (a.nomeSegmento > b.nomeSegmento) { return 1; }
          if (a.nomeSegmento < b.nomeSegmento) { return -1; }
          return 0;
        })
       });

      this.$http
        .get(this.$apiUrl + "/institucional/telas/cadempresas")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.Empresas = retornoAPI.filter((x) => x.visivel)

          this.Empresas.sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }
            if (a.ordem < b.ordem) { return -1; }
            return 0;
          })

          this.TituloSegmento = 'Destaques'
          this.ListaEmp = this.Empresas.filter(x => x.destaque);
        });
  },
  methods: {
    CarregaSegmento2(value){
        this.$http
        .get(this.$apiUrl + "/segmentoInstNivel2/nivel1/" + parseInt(value.segmentoInstNivel1Id,10) )
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.DadosSegmentoNivel2 = retornoAPI.filter((x) => x.visivel)
          this.DadosSegmentoNivel2.sort(function (a, b) {
            if (a.nomeSegmento > b.nomeSegmento) { return 1; }        
            if (a.nomeSegmento < b.nomeSegmento) { return -1; }      
            return 0;
          })
          this.ListaEmp = [];
          this.SegselectedNav= '';
          this.mostra = true;
          this.TituloSegmento = value.nomeSegmento;
          this.SegMobile = this.DadosSegmentoNivel2;
        });
    },
     MudaSegMobile(value) {
      console.log(this.Empresas)
       this.ListaEmp= [];
       this.ListaEmp = this.Empresas.filter(x => x.segmentoInstNivel2.segmentoInstNivel2Id == value.segmentoInstNivel2Id 
                                              && x.segmentoInstNivel2.segmentoInstNivel1.segmentoInstNivel1Id == value.segmentoInstNivel1.segmentoInstNivel1Id)
                                              .sort(function (a, b) {
                                                  if (a.ordem > b.ordem) { return 1; }        
                                                  if (a.ordem < b.ordem) { return -1; }      
                                                  return 0;
                                                })
        this.TituloSegmento = value.segmentoInstNivel1.nomeSegmento;
        this.mostra = true;
    },
  }
}

</script>

<style>
@media (max-width:768px) {
  .alturaimob {
    padding-top: 60px !important;
  }

}
</style>