<template>

<div>
 <!-- slider Area Start-->
    <div class="slider-area ">
      <!-- Mobile Menu -->
      <div class="single-slider slider-height2 d-flex align-items-center"
        :style="{ backgroundImage: 'url(' + $apiUrlIndex + imgTopo + ')'}">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap text-center">
                <h2>{{ topoTitulo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- slider Area End-->

    <!-- ================ contact section start ================= -->
    <section class="contact-section">
            <div class="container"> 
                <div class="row">
                    <div class="col-12">
                        <h2 class="contact-title">Contato</h2>
                    </div>
                    <div class="col-lg-8">
                        <form class="form-contact contact_form">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input v-model="nome" class="form-control valid" name="name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Nome'" placeholder="Nome">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input v-model="email" class="form-control valid" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'E-mail'" placeholder="E-mail">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input v-model="telefone" class="form-control valid" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Telefone'" placeholder="Telefone">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input v-model="atuacao" class="form-control valid" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Área de Atuação'" placeholder="Área de Atuação">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <textarea v-model="mensagem" class="form-control w-100" name="message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Mensagem'" placeholder="Mensagem"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-3">
                                <button type="button" class="button button-contactForm boxed-btn" @click="enviarDadosFormulario">Enviar</button>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-3 offset-lg-1">
                        <div class="media contact-info" style="display: inline-flex;">
                            <span class="contact-info__icon"><i class="ti-home"></i></span>
                            <div class="media-body">
                                <p v-html="contatoEndereco"></p>
                            </div>
                        </div>
                        <div class="media contact-info" style="display: inline-flex;">
                            <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                            <div class="media-body">
                                <p v-html="contatoTelefones"></p>
                            </div>
                        </div>
                        <div class="media contact-info" style="display: inline-flex;">
                            <span class="contact-info__icon"><i class="ti-email"></i></span>
                            <div class="media-body">
                                <h3>{{contatoEmails}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
    <!-- ================ contact section end ================= -->
    
    <Formulario />
  </div>
</template>

<script>
//import Header from '../shared/Header.vue'
import Formulario from '../shared/Formulario.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Formulario,
    //Header
  },
  data() {
    return {

      topoInternas: [],
      topoTitulo: '',
      imgTopo: '',

      contato: [],
      contatoEndereco: '',
      contatoTelefones: '',
      contatoEmails: '',
      contatoEmail:'',
      // Objeto Contato  
      nome: '',
			email: '',
			telefone: '',
      atuacao: '',
			mensagem: '',    
      Contato: {
        NomeEmpresa: "Aqui Garante ",
        Assunto: "Contato",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {


 this.$http
      .get(this.$apiUrl + "/institucional/telas/topocontato")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.topoInternas = retornoAPI

        if (this.topoInternas != '') {
          this.topoTitulo = this.topoInternas[0].nome
          this.imgTopo = this.topoInternas[0].institucionalImg[0].urlImagem
        }

      });
    this.$http
    .get(this.$apiUrl + "/institucional/telas/info%20contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI
      if (this.contato!=''){
          this.contatoEmail = this.contato[0].referencia
          this.contatoEndereco = this.contato[0].resumo1
          this.contatoTelefones = this.contato[0].resumo2
          this.contatoEmails = this.contato[0].nome
      }
    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmail

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome: " + this.nome + 
        " <br /> Telefone: " + this.telefone +
        " <br /> Email: " + this.email +
         " <br /> Atuação: " + this.atuacao +
        " <br /> Descrição: " + this.mensagem

      //validar vazios
      if(this.nome == '' || this.telefone == '' || this.email == '' || this.mensagem == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
        // console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            this.$mensagem_normal("Enviado com sucesso!")
            this.nome = ''
            this.telefone = ''
            this.email = ''
            this.mensagem = ''
            this.atuacao = ''
            this.Contato.Assunto=""
        },error => {
          this.$mensagem_normal(error.bodyText)
          this.nome = ''
          this.telefone = ''
          this.email = ''
          this.atuacao = ''
          this.mensagem = ''
          this.Contato.Assunto=""
        });

      }
     
    }
  }
  
}

</script>

